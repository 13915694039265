<!-- =========================================================================================
  File Name: EditDomain.vue
  Description: User Edit Page

========================================================================================== -->

<template>
  <div id="page-user-edit">

    <vs-alert color="danger" title="Domain Not Found" :active.sync="errorOccurred">
      <span>Domain record with id: {{ $route.params.domainId }} not found. </span>
      <span>
        <span>Check </span><router-link :to="{name:'admin-list-flashcard-domain'}"
                                        class="text-inherit underline">All Domains</router-link>
      </span>
    </vs-alert>

    <vx-card v-if="domainData">

      <div slot="no-body" class="tabs-container px-6 pt-6">

        <vs-tabs v-model="activeTab" class="tab-action-btn-fill-conatiner">
          <vs-tab label="Details" icon-pack="feather" icon="icon-edit">
            <div class="tab-text">
              <domain-details-tab class="mt-4" :data="domainData" v-on:update_domainData="update_domainData"/>
            </div>
          </vs-tab>
          <!---    <vs-tab label="Information" icon-pack="feather" icon="icon-info">
            <div class="tab-text">
          <user-edit-tab-information class="mt-4" :data="user_data" />
            </div>
          </vs-tab> --->
          <!---  <vs-tab label="Social" icon-pack="feather" icon="icon-share-2">
          <div class="tab-text">
          <user-edit-tab-social class="mt-4" :data="user_data" />
            </div>
          </vs-tab> --->
        </vs-tabs>

      </div>

    </vx-card>
  </div>
</template>

<script>
import DomainDetailsTab from './DomainDetailsTab.vue'


export default {
  components: {
    DomainDetailsTab
  },
  data () {
    return {
      domainData: null,
      errorOccurred: false,

      activeTab: 0
    }
  },
  methods: {
    loadDomainData () {
      this.$http.get(`flashcards/domains/${this.$route.params.domainId}`)
        .then(response => {
          if (response.status === 200) {
            this.domainData = {}
            this.domainData.domain_code = response.data.data.code
            this.domainData.domain_id = response.data.data.id
            this.domainData.domain_name = response.data.data.name
            this.domainData.domain_description = response.data.data.description
            this.domainData.domain_status = response.data.data.status
            this.domainData.domain_thumbnail = response.data.data.thumbnail
          }

        })
        .catch(error => {
          this.errorOccurred = true
          this.error_msg = error
        })
    },
    update_domainData (data) {

      const pl = {
        name: data.domain_name,
        code: data.domain_code,
        description: data.domain_description,
        thumbnail: data.domain_thumbnail,
        status: data.domain_status === undefined ? 'active' : data.domain_status
      }

      if (pl.name === undefined) {
        return this.$vs.notify({
          title: 'Failed to update domain',
          text: 'Please provide a Domain Name',
          color: 'danger',
          position: 'top-right'
        })
      }


      this.$vs.loading()

      this.$http.post(`flashcards/domains/${this.$route.params.domainId}`, pl)
        .then(response => {

          if (response.status === 200) {
            this.$vs.loading.close()
            this.$vs.notify({
              title: 'Updated Domain Successfully',
              text: `Domain ${pl.name} has been updated.`,
              color: 'success',
              position: 'top-right'
            })
            return this.loadDomainData()
          }

        })
        .catch(exception => {
          this.$vs.loading.close()

          let error = 'An unknown error occurred while updating this domain'
          if (exception.response) {
            error = exception.response.data.error.description
          }

          return this.$vs.notify({
            title: 'Failed to update domain',
            text: error,
            color: 'danger',
            position: 'top-right'
          })
        })
    }
  },
  created () {
    this.loadDomainData(this.$route.params.domainId)
  }
}

</script>
